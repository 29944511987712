// import { resolve } from "core-js/fn/promise";
import { commonUtil } from "./CommonUtil";
import { appConfig } from "./AppConfig";
import { dateTimeHelper } from "./DateTimeHelper";
import { messsageUtil } from "./MesssageUtil";
import { httpClient } from "./HttpClient";

export default class CommonFunction {
  //*********URL発行(帳票ダウンロード)*********//
  getLink(val) {
    location.href = val;
  }
  //*********URL発行URL発行(帳票ダウンロード)*********//

  //*********コード取得処理(返却値：name)*********//
  getListSetName(list, selected) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].value == selected) {
        return list[i].name;
      }
    }
  }
  //*********コード取得処理(返却値：name)*********//

  //*********コード取得処理(返却値：text)*********//
  getListSetText(list, selected) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].value == selected) {
        return list[i].text;
      }
    }
  }
  //*********コード取得処理(返却値：text)*********//

  //*********カンマ区切り処理(小数点以下なし) 変換する値,上限(整数)*********//
  getDelimiter(val, inte) {
    console.log(val);
    if (val != "" && val != null) {
      var comma = commonUtil.zen2han(val).replaceAll(",", "");
      comma = comma.replace(/[^0-9]/gi, "");
      if (comma.length > inte) {
        comma = comma.substring(0, inte);
      }
      if (!isNaN(Number(comma))) {
        return commonUtil.formatToCurrency(Number(comma));
      }
    }
    return "";
  }
  //*********カンマ区切り処理(小数点以下なし)*********

  //*********カンマ除去処理 変更する項目、上限************************
  getCommaRemoval(val) {
    var proQty = commonUtil.zen2han(val).replaceAll(",", "");
    return proQty;
  }
  //*********カンマ除去処理************************

  //*********カンマ区切り処理(小数点以下あり) 変換する値,上限(整数),上限(少数)*********
  getDelimiterFew(val, inte, few) {
    if (val != "") {
      var comma = commonUtil.zen2han(val).replaceAll(",", "");
      if (comma != null && comma != "0" && comma != "") {
        if (!comma.includes(".")) comma = comma + ".0";
        let num = comma.split(".");
        num[0] = num[0].replace(/[^0-9]/gi, "");
        num[1] = num[1].replace(/[^0-9]/gi, "");
        if (num[0].length > inte) {
          num[0] = num[0].substring(0, inte);
        }
        if (num[1].length > few) {
          num[1] = num[1].substring(0, few);
        }
        comma = num[0] + "." + num[1];
        if (comma == 0) {
          comma = 0;
        }
        if (!isNaN(Number(comma))) {
          return commonUtil.formatToCurrency(Number(comma));
        }
      }
    }
  }
  //*********カンマ区切り処理(小数点以下あり)*********

  //*********日付を直接入力時************************
  directInput(val) {
    var strDt = null;
    var inputVal = commonUtil.zen2han(val);
    if (inputVal.length == 8) {
      strDt = inputVal.substr(0, 4) + "-" + inputVal.substr(4, 2) + "-" + inputVal.substr(6, 2);
    } else {
      strDt = inputVal.substr(0, 4) + "-" + inputVal.substr(5, 2) + "-" + inputVal.substr(8, 2);
    }
    if (dateTimeHelper.validDate(strDt, "-")) {
      return strDt;
    } else {
      return null;
    }
  }

  //*********日付に+1する*********
  addDate(dateCal, changetime) {
    if (dateCal == null || dateCal == "") {
      return dateCal;
    }
    let date = new Date(dateCal);
    if (changetime == "add") {
      return dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    } else {
      return dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    }
  }
  //*********日付に+1する*********

  /**
   * 業務排他API
   * @param {String} screenId 画面ID
   * @param {String} procDiv 処理区分｛01:ロック獲得、02:ロック上書き、03:ロック終了、04:ロック削除｝
   * @param {String} clientSid 取引先SID
   * @param {String} updateDatetime 更新日時
   *
   * @returns レスポンス
   */
  exclusiveReserve(screenId = "X-XXX-XXX", procDiv, clientSid = void 0, updateDatetime = void 0) {
    const body = httpClient.createRequestBodyConfig();
    // 共通IF項目 画面ID
    body.reqCom.reqComComponentId = screenId;
    body.reqIdv.procDiv = procDiv;
    body.reqIdv.clientSid = clientSid;
    body.reqIdv.updateDatetime = updateDatetime;

    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .securePost(appConfig.API_URL.EXCLUSIVE_RESERVE, body, appConfig.APP_CONFIG)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          if (jsonData.resCom.resComCode == "000") {
            // 正常時
            resolve(jsonData);
          } else {
            // エラー時
            // 強制割込み確認エラーと他のエラーを呼び出し元で判定するために
            // ここでは、メッセージではなくオブジェクトごと返却する
            reject(jsonData);
          }
        })
        .catch(() => {
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }
  /**
   * 割込み確認が必要か判定する
   * @param {*} responseJson 業務排他APIのレスポンスJSON
   */
  isExclusiveReserveNeedInterrupt(response) {
    let messageId = response.resCom ? response.resCom.resComMessageId : "";
    switch (messageId) {
      case "A-999-999_021_E":
        return true;
      default:
        return false;
    }
  }
}

export const commonFunction = new CommonFunction();
