import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  // eslint-disable-next-line no-unused-vars
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { appCertification } from "./AppCertification";
import { config as awsConfig, CognitoIdentityCredentials } from "aws-sdk";

// AWSのインスタンスの生成
const AWS = require("aws-sdk");
AWS.config.update({ region: sessionStorage.getItem("COGNITO.REGION") });

export class Cognito {
  /**
   * 設定を行います。
   * @param {String} username
   */
  configure(username) {
    this.cognitoUserPool = new CognitoUserPool({
      UserPoolId:
        sessionStorage.getItem("shipperKbn") == "0"
          ? appCertification.COGNITO.USER_POOL_ID
          : appCertification.SHIPPER_COGNITO.USER_POOL_ID,
      ClientId:
        sessionStorage.getItem("shipperKbn") == "0"
          ? appCertification.COGNITO.APP_CLIENT_ID
          : appCertification.SHIPPER_COGNITO.APP_CLIENT_ID,
      Storage: sessionStorage,
    });
    this.userData = { Username: username, Pool: this.cognitoUserPool, Storage: sessionStorage };
  }

  /**
   * username, passwordでログインします。
   * @param {String} username
   * @param {String} password
   * @returns {Promise<any>}
   */
  login(username, password) {
    this.configure(username);
    sessionStorage.setItem("usercode", username);
    sessionStorage.setItem("userps", password);
    const cognitoUser = new CognitoUser(this.userData);
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          this.saveToken(result);
          sessionStorage.setItem("updateToken", new Date().getTime());
          resolve(result);
        },
        onFailure: (err) => {
          console.error("login Fail", err);
          reject(err);
        },
      });
    });
  }

  /**
   * cognitoへ更新トークンを使用して再取得を試みます。
   * （AWS仕様：残り時間が十分にある場合、同じトークンが返されます）
   * @param {String} username
   * @returns
   */
  updateRefreshToken(username) {
    this.configure(username);
    const cognitoUser = this.cognitoUserPool.getCurrentUser();
    //const cognitoUser = new CognitoUser(this.userData);
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((error, session) => {
        if (session.isValid()) {
          this.saveToken(session);
          resolve(session);
        } else {
          console.error("updateRefreshToken error", error);
          reject(error);
        }
      });
    });
  }

  /**
   * ログアウトします。
   * @param {String} username
   */
  logout(username) {
    this.configure(username);
    if (this.cognitoUserPool.getCurrentUser() != null) {
      if (awsConfig.credential && awsConfig.credentials.clearCachedId) {
        awsConfig.credentials.clearCachedId();
      }
    }
    this.cognitoUserPool.getCurrentUser().signOut();
    this.clearToken();
  }

  /**
   * アクセストークン、またはIDトークンの残り時間(秒)を返します。
   * 今後の仕様変更時の調査工数低減のため、残しておく。
   * @param {String} token
   * @returns {Integer}
   */
  getTokenRemainingTime(token) {
    const payload = token.split(".")[1];
    const decodePayload = JSON.parse(
      window.atob(payload.replaceAll("-", "+").replaceAll("_", "/"))
    );
    // 現在の時刻を秒で取得する。
    const nowSec = Math.floor(new Date().getTime() / 1000);
    return decodePayload.exp - nowSec;
  }

  /**
   * 有効期間に合わせて再取得したIDトークンを返します。
   * @returns {Promise<any>}
   */
  getIdToken() {
    // MEMO(仕様) 残り時間に関わらずトークン再取得を呼び出すためコメントアウト
    // 今後の仕様変更時の調査工数低減のため、残しておく。
    // const idToken = sessionStorage.getItem("idToken");
    // var tokenRemainingTime = this.getTokenRemainingTime(idToken);
    // if (tokenRemainingTime >= 120) {
    //   return Promise.resolve(idToken);
    // } else {
    const username = sessionStorage.getItem("usercode");
    return new Promise((resolve, reject) => {
      this.updateRefreshToken(username)
        .then((result) => {
          if (result.isValid()) {
            resolve(result.idToken.jwtToken);
          } else {
            reject(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
    // }
  }

  /**
   * API利用に必要なクレデンシャルをIDプールから取得します。
   * @param {String} idToken
   * @returns {any}
   *
  getApiGatewayCredentials(idToken) {
    this.getTokenRemainingTime(idToken);
    return Promise.resolve(true);
    /*
    // GlobalConfigInstanceをスレッドセーフにすることが実装上難しいため、タイミングロックする。
    var tokenRemainingTime = this.getTokenRemainingTime(idToken);
    if (awsConfig.credentials && awsConfig.credentials.accessKeyId && tokenRemainingTime >= 700) {
      return Promise.resolve(awsConfig.credentials);
    } else {
      return new Promise((resolve, reject) => {
        awsConfig.region = appCertification.COGNITO.REGION;

        // ログイン状態でCredentialsを生成
        awsConfig.credentials = new CognitoIdentityCredentials({
          IdentityPoolId: appCertification.COGNITO.IDENTITY_POOL_ID,
          Logins: {
            [`cognito-idp.${appCertification.COGNITO.REGION}.amazonaws.com/${appCertification.COGNITO.USER_POOL_ID}`]:
              idToken,
          },
        });

        const _awsConfig = awsConfig;

        return _awsConfig.credentials
          .getPromise()
          .then(() => {
            resolve(_awsConfig.credentials);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    *
}
  */
  makeCredentials(idToken) {
    return new Promise((resolve, reject) => {
      awsConfig.region = sessionStorage.getItem("COGNITO.REGION");

      // ログイン状態でCredentialsを生成
      awsConfig.credentials = new CognitoIdentityCredentials({
        IdentityPoolId: sessionStorage.getItem("COGNITO.IDENTITY_POOL_ID"),
        Logins: {
          [`cognito-idp.${sessionStorage.getItem("COGNITO.REGION")}.amazonaws.com/${
            sessionStorage.getItem("shipperKbn") == "0"
              ? appCertification.COGNITO.USER_POOL_ID
              : appCertification.SHIPPER_COGNITO.USER_POOL_ID
          }`]: idToken,
        },
      });
      awsConfig.credentials.clearCachedId();
      const _awsConfig = awsConfig;

      return _awsConfig.credentials
        .getPromise()
        .then(() => {
          sessionStorage.setItem("credentials.accessKeyId", _awsConfig.credentials.accessKeyId);
          sessionStorage.setItem(
            "credentials.secretAccessKey",
            _awsConfig.credentials.secretAccessKey
          );
          sessionStorage.setItem("credentials.sessionToken", _awsConfig.credentials.sessionToken);
          resolve(_awsConfig.credentials);
        })
        .catch((err) => {
          console.error("makeCredentials error", err);
          reject(err);
        });
    });
  }
  /**
   * CognitoUserSessionからロジック上必要な情報を保存します。
   * ※必要なトークン情報はCognitoライブラリの管理下ですのため意識しません。
   * @param {CognitoUserSession} session
   */
  saveToken(session) {
    // アクセストークン(cognitoライブラリで管理されるためコメントアウト)
    //sessionStorage.setItem("accessToken", session.accessToken.jwtToken);
    // アクセストークの有効期限
    sessionStorage.setItem("accessToken.payload.exp", session.accessToken.payload.exp);
    sessionStorage.setItem("accessToken.payload.iat", session.accessToken.payload.iat);
    // IDトークン(ライブラリ管理はされるが取り出す機会が多いため別名で保持しておく)
    sessionStorage.setItem("idToken", session.idToken.jwtToken);
    // IDトークンの有効期限
    sessionStorage.setItem("idToken.payload.exp", session.idToken.payload.exp);
    // 更新トークン
    sessionStorage.setItem("refreshToken", session.refreshToken.token);
    // ユーザー名 ユーザー名を上書きする必要はないためコメントアウト
    //sessionStorage.setItem("username", session.accessToken.payload.username);
    // グループ(役割) 【仕様】rolesではなくgroupsを格納します。
    let role = session.idToken.payload["cognito:groups"];
    if (role === undefined) {
      role = [];
    }
    sessionStorage.setItem("role", role);
    // 姓
    sessionStorage.setItem("family_name", session.idToken.payload["family_name"]);
    // 名
    sessionStorage.setItem("given_name", session.idToken.payload["given_name"]);
    // 営業所
    sessionStorage.setItem(
      "sales_office_code",
      session.idToken.payload["custom:sales_office_code"]
    );

    // ログイン時間
    sessionStorage.setItem("accessToken", new Date().getTime());
  }

  /**
   * セッションストレージをクリアします。
   * ※Cognito管理下のトークン情報の削除にはログアウトが適切です。
   */
  clearToken() {
    // アクセストークの有効期限
    sessionStorage.removeItem("accessToken.payload.exp");
    sessionStorage.removeItem("accessToken.payload.iat");
    // IDトークン
    sessionStorage.removeItem("idToken");
    // IDトークンの有効期限
    sessionStorage.removeItem("idToken.payload.exp");
    // 更新トークン
    sessionStorage.removeItem("refreshToken");
    // ユーザー名
    sessionStorage.removeItem("usercode");
    // グループ(役割)
    sessionStorage.removeItem("role");
    // 姓
    sessionStorage.removeItem("family_name");
    // 名
    sessionStorage.removeItem("given_name");
    // 営業所
    sessionStorage.removeItem("sales_office_code");
    // 営業所SID
    sessionStorage.removeItem("sales_office_sid");
    // ログイン時間
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("updateToken");
    //どうしても自前でクリアしたい場合、以下を有効化してください。
    //CognitoIdentityServiceProvider.13qo9uaiej7r8lqoh9dj87fonb.test01.accessToken
    // const userName = sessionStorage.removeItem(
    //   "CognitoIdentityServiceProvider." + appCertification.COGNITO.APP_CLIENT_ID + ".LastAuthUser"
    // );
    // if (userName) {
    //   const nameAry = ["accessToken", "idToken", "refreshToken", "clockDrift"];
    //   for (let name of nameAry) {
    //     const key =
    //       "CognitoIdentityServiceProvider." +
    //       appCertification.COGNITO.APP_CLIENT_ID +
    //       "." +
    //       userName +
    //       "." +
    //       name;
    //     sessionStorage.removeItem(key);
    //   }
    // }
  }

  /**
   * ログインしているかを判定します。
   * @param {String} username
   * @returns {CognitoUserSession}
   */
  isAuthenticated(username) {
    this.configure(username);
    const cognitoUser = this.cognitoUserPool.getCurrentUser();
    if (cognitoUser === null) {
      Promise.reject(cognitoUser);
    }
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(session);
          } else {
            resolve(session);
          }
        }
      });
    });
  }

  /**
   * 指定したユーザーの属性を取得します。
   * @param {String} username
   * @returns {Map}
   */
  getUserAttribute(username) {
    this.configure(username);
    const cognitoUser = new CognitoUser(this.userData);
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err || !session.isValid()) {
          reject(err);
          return;
        }
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
            reject(err);
            return;
          }
          // 取得した属性情報を連想配列に格納
          const map = new Map();
          for (let i = 0; i < result.length; i++) {
            map.set(result[i].getName(), result[i].getValue());
          }
          resolve(map);
        });
      });
    });
  }

  // /**
  //  * パスワードの変更
  //  * @param {String} userName
  //  * @param {String} oldPassword
  //  * @param {String} newPassword
  //  */
  // changePasswordUser(userName, oldPassword, newPassword) {
  // this.configure(userName);
  // const cognitoUser = new CognitoUser(this.userData);

  // //パスワードを変更されるユーザー情報
  // var authenticationData = {
  //   Username: userName,
  //   Password: oldPassword,
  // };

  // // 実行
  // return new Promise((resolve, reject) => {
  //   var authenticationDetails = new AuthenticationDetails(authenticationData);

  //   //cognitoへのログイン
  //   cognitoUser.authenticateUser(authenticationDetails, {
  //     onSuccess: function (result) {
  //       // cognitoへのログイン成功
  //       console.log("authenticateUser", result);
  //       //パスワード変更処理
  //       cognitoUser.changePassword(oldPassword, newPassword, function (err, rel) {
  //         if (err) {
  //           // パスワード変更失敗
  //           console.error(err);
  //           reject(err);
  //         } else {
  //           // パスワード変更成功
  //           console.log("changePassword", rel);

  //           // 変更したユーザーが自身だった場合、ログイン時のパスワードも変更する
  //           if (userName == sessionStorage.getItem("usercode")) {
  //             sessionStorage.setItem("userps", newPassword);
  //           }
  //           resolve(rel);
  //         }
  //       });
  //     },
  //     onFailure: function (err) {
  //       console.error("authenticateUser error", err);
  //       // ログイン失敗
  //       reject(err);
  //     },
  //   });
  // });
  // }

  /**
   * パスワードの変更
   * @param {String} userName
   * @param {String} oldPassword
   * @param {String} newPassword
   */
  changePassword(userName, oldPassword, newPassword) {
    // 実行
    return new Promise((resolve, reject) => {
      const AWS1 = require("aws-sdk");

      const cognitoUser = new AWS1.CognitoIdentityServiceProvider({
        accessKeyId: sessionStorage.getItem("COGNITO.AWS_ACCESS_KEY_ID"),
        secretAccessKey: sessionStorage.getItem("COGNITO.AWS_SECRET_ACCESS_KEY"),
        region: sessionStorage.getItem("COGNITO.REGION"),
      });

      const params = {
        UserPoolId:
          sessionStorage.getItem("shipperKbn") == "0"
            ? appCertification.COGNITO.USER_POOL_ID
            : appCertification.SHIPPER_COGNITO.USER_POOL_ID, // required
        Username: userName, // required
        Password: newPassword, // required
        Permanent: true,
      };

      cognitoUser
        .adminSetUserPassword(params)
        .promise()
        .then((data) => {
          console.log(data);
          // 変更したユーザーが自身だった場合、ログイン時のパスワードも変更する
          if (userName == sessionStorage.getItem("usercode")) {
            sessionStorage.setItem("userps", newPassword);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          // // ユーザーのパスワードの変更
          // this.changePasswordUser(userName, oldPassword, newPassword).finally(() => {
          // 変更が終わったらログインしなおす
          this.login(sessionStorage.getItem("usercode"), sessionStorage.getItem("userps"))
            .then((session) => {
              console.log("changePassword login", session);
              resolve(session);
            })
            .catch((error) => {
              console.error("changePassword login error", error);
              reject(error);
            });
        });
    });
  }

  /**
   * ユーザー名の変更
   * @param {*} userName
   * @param {*} familyName
   * @param {*} givenName
   * @returns
   */
  changeUserName(userName, familyName, givenName) {
    return new Promise((resolve, reject) => {
      // 姓名
      const params = {
        UserPoolId:
          sessionStorage.getItem("shipperKbn") == "0"
            ? appCertification.COGNITO.USER_POOL_ID
            : appCertification.SHIPPER_COGNITO.USER_POOL_ID,
        Username: userName,
        UserAttributes: [
          {
            Name: "family_name",
            Value: familyName,
          },
          {
            Name: "given_name",
            Value: givenName,
          },
        ],
      };

      const AWS1 = require("aws-sdk");
      const cognitoUser = new AWS1.CognitoIdentityServiceProvider({
        accessKeyId: sessionStorage.getItem("COGNITO.AWS_ACCESS_KEY_ID"),
        secretAccessKey: sessionStorage.getItem("COGNITO.AWS_SECRET_ACCESS_KEY"),
        region: sessionStorage.getItem("COGNITO.REGION"),
      });

      // 姓名変更
      cognitoUser
        .adminUpdateUserAttributes(params)
        .promise()
        .then((data) => {
          console.log(data);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  /**
   * ユーザー登録
   * @param {*} userName
   * @param {*} password
   * @param {*} familyName
   * @param {*} givenName
   * @returns
   */
  userRegistration(userName, password, familyName, givenName) {
    const AWS = require("aws-sdk");

    this.configure(userName);

    const cognito = new AWS.CognitoIdentityServiceProvider({
      accessKeyId: sessionStorage.getItem("COGNITO.AWS_ACCESS_KEY_ID"),
      secretAccessKey: sessionStorage.getItem("COGNITO.AWS_SECRET_ACCESS_KEY"),
      region: sessionStorage.getItem("COGNITO.REGION"),
    });

    // 実行
    return new Promise((resolve, reject) => {
      // 検索ユーザー情報
      var searchUser = {
        UserPoolId:
          sessionStorage.getItem("shipperKbn") == "0"
            ? appCertification.COGNITO.USER_POOL_ID
            : appCertification.SHIPPER_COGNITO.USER_POOL_ID,
        Filter: 'username = "' + userName + '"',
      };

      cognito.listUsers(searchUser, function (err, rel) {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          console.log("listUsers", rel);
          if (rel.Users.length == 0) {
            // 登録ユーザー情報
            var user = {
              UserPoolId: searchUser.UserPoolId,
              Username: userName,
              UserAttributes: [
                {
                  Name: "family_name",
                  Value: familyName,
                },
                {
                  Name: "given_name",
                  Value: givenName,
                },
              ],
            };
            // ユーザー登録
            cognito.adminCreateUser(user, function (err, rel) {
              if (err) {
                console.error(err);
                reject(err);
              } else {
                console.log("createUser", rel);
                // パスワード設定情報
                var userPassword = {
                  UserPoolId: user.UserPoolId,
                  Username: userName,
                  Password: password,
                  Permanent: true,
                };

                // パスワード変更
                cognito.adminSetUserPassword(userPassword, function (err, rel) {
                  if (err) {
                    console.error(err);
                    reject(err);
                  } else {
                    console.log("setUserPassword", rel);
                    resolve(rel);
                  }
                });
                resolve(rel);
              }
            });
          } else {
            // パスワード設定情報
            var userPassword = {
              UserPoolId: searchUser.UserPoolId,
              Username: userName,
              Password: password,
              Permanent: true,
            };
            // 姓名
            const userFullName = {
              UserPoolId:
                sessionStorage.getItem("shipperKbn") == "0"
                  ? appCertification.COGNITO.USER_POOL_ID
                  : appCertification.SHIPPER_COGNITO.USER_POOL_ID,
              Username: userName,
              UserAttributes: [
                {
                  Name: "family_name",
                  Value: familyName,
                },
                {
                  Name: "given_name",
                  Value: givenName,
                },
              ],
            };

            // パスワード変更
            cognito.adminSetUserPassword(userPassword, function (err, rel) {
              if (err) {
                console.error(err);
                reject(err);
              } else {
                console.log("setUserPassword", rel);
                resolve(rel);
              }
            });

            // 姓名変更
            cognito
              .adminUpdateUserAttributes(userFullName)
              .promise()
              .then((data) => {
                console.log(data);
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          }
        }
      });
    });
  }
}

export const cognito = new Cognito();
