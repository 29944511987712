//import axios from "axios";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { inputRules } from "./assets/scripts/js/InputRules";
import { httpClient } from "./assets/scripts/js/HttpClient";
import { i18n } from "./lang/lang.js";
Vue.config.productionTip = false;

//axios.defaults.timeout = 30000;
//axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf-8";
//Vue.prototype.$axios = axios;

Vue.prototype.$httpClient = httpClient;

// 全画面で使用できるルールの設定
Vue.prototype.$inputRules = inputRules;

new Vue({
  i18n,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
