import { i18n } from "../../../lang/lang.js";

/**
 * 共通入力チェック
 */
export class InputRules {
  /**
   * 必須入力チェック
   * ヘッダのisRequiredがtrueになっている項目を判定
   * @param {String} header
   * @param {Object} rowItem
   * @returns
   */
  isRequiredRow(header, rowItem) {
    // 必須項目を検索
    let requireds = header.filter((h) => h.isRequired ?? false);

    // 入力項目の一覧を取得
    let inputValues = {};
    requireds.forEach((h) => (inputValues[h.value] = rowItem[h.value] ?? ""));

    // 判定結果の返却
    return (
      requireds.every((h) => inputValues[h.value] == "") ||
      requireds.every((h) => inputValues[h.value] != "") ||
      i18n.tc("check.chk_is_required")
    );
  }

  /**
   * 作成済みの場合、必須チェック
   * @param {String} sid
   * @param {String} value
   */
  isCreatedRowRequired(sid, value) {
    // 判定結果の返却
    return !(sid ?? false) || !!(value ?? false) || i18n.tc("check.chk_is_required");
  }
}

export const inputRules = new InputRules();
