import Vue from "vue";
import VueI18n from "vue-i18n";

let locale = "en";

let message = {}; // default: "en"

const language = window.navigator.language;

if (language) {
  const lang = language.toLowerCase().split("-");
  switch (lang[0]) {
    case "ja": // 日本語: ja, ja-JP
      locale = lang[0];
      break;
    case "es": // スペイン語: es, es-*
      locale = lang[0];
      break;
    case "ko": // 韓国語
      locale = lang[0];
      break;
    case "zhcn": // 中国語 (簡体)
      locale = lang[0];
      break;
    case "zhtw": // 中国語 (繁体)
      locale = lang[0];
      break;

    case "zh": // 中国語: zh-*
      switch (lang[1]) {
        case "cn": // 中国
          locale = "zhcn"; // 中国語 (簡体)
          break;
        case "sg": // シンガポール
          locale = "zhcn"; // 中国語 (簡体)
          break;
        case "hans":
          locale = "zhcn"; // 中国語 (簡体)
          break;
        case "tw": // 台湾
          locale = "zhtw"; // 中国語 (繁体)
          break;
        case "hk": // 香港
          locale = "zhtw"; // 中国語 (繁体)
          break;
        case "mo": // マカオ
          locale = "zhtw"; // 中国語 (繁体)
          break;
        case "hant":
          locale = "zhtw"; // 中国語 (繁体)
          break;
      }

      break;
  }
}

message[locale] = require(`./${locale}.json`);
sessionStorage.setItem("lang", locale);
Vue.use(VueI18n);

export let i18n = new VueI18n({
  locale,
  messages: message, // 必要な言語ファイルのみ設定する
});
